import logo from './logo.svg';
import portrait from './images/portrait.jpeg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={portrait}  alt="logo" />
        <p>
          Richard Nielsen's Memoriam will return in November 2020.
        </p>
      </header>
    </div>
  );
}

export default App;
